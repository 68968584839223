class Api {
  constructor({ url, headers, data }) {
    this._url = url;
  }

  _requestResult(res) {
    if (res.ok) {
      return res.json();
    } else {
      return Promise.reject(`Ошибка: ${res.status}`);
    }
  }

  sendMessage(name, tel, problem) {
    return fetch(`${this._url}/send`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        tel,
        problem
      })
    }).then((res) => 
      this._requestResult(res)
    )
    .catch((e) => {
      return e;
    })
  }

}

export const api = new Api({
  url: ''});