import { useState } from 'react';
import InputMask from 'react-input-mask';

function Connection({onSendMessage}) {
  const [name, setName] = useState('');
  const [tel, setTel] = useState('');
  const [problem, setProblem] = useState('');

  const handleChangeName = (e) => {
    setName(e.target.value);
  };
  const handleChangeTel = (e) => {
    setTel(e.target.value);
  };
  const handleChangeProblem = (e) => {
    setProblem(e.target.value);
  };

  const clearInputs = () => {
    setName('');
    setTel('');
    setProblem('');
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    onSendMessage({
      name,
      tel,
      problem,
    });

    clearInputs();
  };

  return(
    <section className="connection">
        <div className="connection__container">
          <h2 className="connection__title">
            Отправьте запрос <br className="connection__title_br" />
            на бесплатную консультацию
          </h2>
          <h3 className="connection__subtitle">
            Наш ведущий юрист свяжется <br className="connection__subtitle_br" />
            с Вами в ближайшее время
          </h3>
          <form className="connection__form" method="post" onSubmit={handleSubmit}>
            <input
            type='text'
            className="form__input"
            placeholder="Имя"
            name="name"
            required
            minLength="2"
            maxLength="40"
            value={name}
            onChange={handleChangeName}
          />
          <InputMask
            id="tel"
            type="tel"
            className="form__input"
            mask="+7 (999) 999 99-99"
            placeholder="+7 (___) ___ __-__"
            value={tel}
            onChange={handleChangeTel}
            required
            name="tel"
          />
            <input
            type='text'
            className="form__input"
            placeholder="Ваша проблема"
            name="problem"
            required
            minLength="2"
            maxLength="300"
            value={problem}
            onChange={handleChangeProblem}
          />
            <button className="form__button" type="submit">
              Получить консультацию
            </button>
          </form>
        </div>
      </section>
  )
}

export default Connection;