function Statistics() {
  return (
    <section className="statistics">
        <div className="statistics__container">
          <div className="statistics__info-container">
            <div className="statistics__info">
              <h2 className="statistics__title">46</h2>
              <p className="statistics__description">Юристов в штате</p>
            </div>
            <div className="statistics__info">
              <h2 className="statistics__title">9468</h2>
              <p className="statistics__description">Консультаций проведено</p>
            </div>
            <div className="statistics__info">
              <h2 className="statistics__title">4237</h2>
              <p className="statistics__description">Выигранных дел</p>
            </div>
          </div>
        </div>
      </section>
  )
}

export default Statistics;