function Info() {
  return(
    <section className="info" id="info">
        <div className="info__container">
          <iframe
            title="Карта"
            className="info__map"
            src="https://yandex.ru/map-widget/v1/?um=constructor%3A3c257be1466db9a2c4037ff14e2a0a0ea98de5023659002acee42b700db5c300&amp;source=constructor"
            frameBorder="0"
          ></iframe>
          <div className="info__text">
            <h2 className="info__title">Правовая коллегия Юстиции</h2>
            <p className="info__contacts">
              «Офисный центр», офис 13 <br />
              Красная улица, 145/1<br />
              Краснодар, Россия, 350065
            </p>
            <p className="info__contacts">
              Почта: pku23@mail.ru<br />
              Телефон: +7 (909) 444-19-17<br />
              Отдел контроля качества: +7 (991) 077-40-58
            </p>
            <p className="info__time">
              Время работы: пн-пт 10:00-19:00, сб-вс 10:00-16:00
            </p>
          </div>
        </div>
      </section>
  )
}

export default Info;