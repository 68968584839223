import { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';

function Popup({isOpen, onClose, onSendMessage }) {
  const [name, setName] = useState('');
  const [tel, setTel] = useState('');
  const [problem, setProblem] = useState('');

  useEffect(() => {
    setName('');
    setTel('');
    setProblem('');
  }, [isOpen]);

  const handleChangeName = (e) => {
    setName(e.target.value);
  };
  const handleChangeTel = (e) => {
    setTel(e.target.value);
  };
  const handleChangeProblem = (e) => {
    setProblem(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    onSendMessage({
      name,
      tel,
      problem,
    });
  };


  return(
    <div className={`popup ${isOpen ? 'popup_opened' : ''}`}>
      <div className="popup__container">
        <button
        className="popup__close"
        id="close_editProfile"
        type="button"
        aria-label="Закрыть"
        onClick={onClose}
      ></button>
      <form className="popup__form" id="popup__form" method="post" onSubmit={handleSubmit} >
        <h2 className="popup__title">Экспресс консультация юриста</h2>
        <input
            type="text"
            className="popup__input popup__input_type_name"
            placeholder="Имя"
            name="name"
            id="name-input"
            required
            minLength="2"
            maxLength="40"
            value={name}
            onChange={handleChangeName}
          />
          <InputMask
            id="tel"
            type="tel"
            className="popup__input popup__input_type_tel"
            mask="+7 (999) 999 99-99"
            placeholder="+7 (___) ___ __-__"
            value={tel}
            onChange={handleChangeTel}
            required
            name="tel"
          />
          <input
            type="text"
            className="popup__input popup__input_type_problem"
            placeholder="Ваша проблема"
            name="problem"
            required
            minLength="2"
            maxLength="300"
            value={problem}
            onChange={handleChangeProblem}
        />
        <button className="popup__button" type="submit">
          Получить консультацию бесплатно
        </button>
      </form>
      </div>
    </div>
  )
}

export default Popup;