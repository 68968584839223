import Inna from '../images/profile2.png';
import Tanya from '../images/profile1.png';
import Aleksey from '../images/profile4.png';

function Reviews() {
  return (
    <section className="reviews" id="reviews">
        <div className="reviews__container">
          <h2 className="reviews__title">Отзывы наших клиентов</h2>
          <h3 className="reviews__subtitle">
            Люди доверяют нам <br className="br" />
            в самых сложных вопросах,<br />а мы оправдываем все их ожидания.
          </h3>
          <div className="reviews__cards">
            <div className="reviews__card">
              <div className="reviews__card__profile">
                <img
                  className="reviews__card__photo"
                  src={Inna}
                  alt="Фото профиля"
                />
                <h3 className="reviews__card__name">Инна Лопатина</h3>
              </div>
              <p className="reviews__card__review">
                Добрый день. Обращалась к Диане Исааковне за консультацией.
                Буквально за час мы с ней обговорили все возможные пути решения
                вопроса. Рассказала мне, в какие инстанции нужно обратиться. К
                счастью, все получилось. Была в нескольких компаниях, но такую
                полную консультацию получила только здесь. Отправила к ним маму,
                уже запустили процесс.
              </p>
            </div>
            <div className="reviews__card">
              <div className="reviews__card__profile">
                <img
                  className="reviews__card__photo"
                  src={Tanya}
                  alt="Фото профиля"
                />
                <h3 className="reviews__card__name">Татьяна Васильева</h3>
              </div>
              <p className="reviews__card__review">
                Здравствуйте. Меня зовут Татьяна Васильева. Полгода назад
                обращалась в компанию, чтобы юристы помогли мне избавиться от
                коллекторов. Так на консультации узнала, что могу списать долги
                и меня больше не побеспокоят. Хочу поблагодарить Яна
                Владимировича за его профессионализм. Было не просто довериться
                кому-то в таком серьезном вопросе, но он смог оправдать мои
                надежды. Сейчас хоть смогла вздохнуть свободно без долгов.
                Спасибо!
              </p>
            </div>
            <div className="reviews__card">
              <div className="reviews__card__profile">
                <img
                  className="reviews__card__photo"
                  src={Aleksey}
                  alt="Фото профиля"
                />
                <h3 className="reviews__card__name">Алексей Сидоренко</h3>
              </div>
              <p className="reviews__card__review">
                Здравствуйте! Пришел по рекомендации к ребятам по вопросу
                расторжения договора через суд. По правде говоря, больших
                ожиданий не было. Уже был негативный опыт, связанный с юристами,
                поэтому скептически к этому всему относился. На консультации
                юрист буквально разжевала мне поэтапно все юридические действия,
                как процесс судебный будет проходить и тд. Чтобы переварить,
                ушел домой, всё обдумал и вернулся уже на заключение договора.
                Кстати, хочу сказать, цены адекватные, в той юрке мне в 2 раза
                больше сказали. Суд по итогу выиграли. Благодарю моих юристов,
                что несколько месяцев меня поддерживали. Привет от Алексея
                Романовича Сидоренко.
              </p>
            </div>
          </div>
        </div>
      </section>
  )
}

export default Reviews;