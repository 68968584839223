function Menu() {
  return (
    <section className="menu">
        <div className="menu__container">
          <nav className="menu__nav">
            <ol className="menu__nav">
              <li className="menu__item">
                <a className="menu__item__link" href="#help">Услуги</a>
              </li>
              <li className="menu__item">
                <a className="menu__item__link" href="#reviews">Отзывы</a>
              </li>
              <li className="menu__item">
                <a className="menu__item__link" href="#info">Контакты</a>
              </li>
            </ol>
          </nav>
        </div>
      </section>
  )
}

export default Menu;