import Logo from '../images/Logo.svg'

function Header() {
  return (
    <header className="page__header header">
      <div className="header__logo">
        <img
          className="header__logo__type_image"
          src={Logo}
          alt="Логотип"
        />
        <h3 className="header__logo__type_title">Юридическая помощь</h3>
      </div>
      <div className="header__info">
        <p className="header__info__type_number">+7 (909) 444-19-17</p>
        <p className="header__info__type_time">
          Время работы: пн-пт 10:00-19:00<br />сб-вс 10:00-16:00
        </p>
      </div>
    </header>
  )
}

export default Header;