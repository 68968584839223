import Lead from "./Lead";
import Menu from "./Menu";
import Help from "./Help";
import Statistics from "./Statistics";
import Reviews from "./Reviews";
import Tired from "./Tired";
import Connection from "./Connection";
import Info from "./Info";

function Main({onGetHelpClick, onSendMessage}) {
  return (
    <main className='content'>
      <Lead onGetHelpClick={onGetHelpClick} />
      <Menu />
      <Help onGetHelpClick={onGetHelpClick} />
      <Statistics />
      <Reviews />
      <Tired />
      <Connection onSendMessage={onSendMessage} />
      <Info />
    </main>
  )
}

export default Main;