function Footer() {
  return(
    <footer className="footer">
      <div className="footer__container">
        <p className="footer__description">
          Вся информация представлена на сайте в ознакомительных целях и не
          является публичной офертой. Требуется консультация специалиста.
        </p>
        <p className="footer__copyright">&copy;2024 Правовая коллегия Юстиции</p>
      </div>
    </footer>
  )
}

export default Footer;