import Header from "./Header";
import Main from "./Main";
import Footer from "./Footer";
import Popup from "./Popup";
import { useState, useEffect } from 'react';
import { api } from "../utils/Api";


function App() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleGetHelpClick = () => {
    setIsPopupOpen(true);
  }

  const closePopup = () => {
    setIsPopupOpen(false);
  }

  useEffect(() => {
    const close = (e) => {
      if(e.keyCode === 27){
        closePopup();
      }
    }
    window.addEventListener('keydown', close)
  return () => window.removeEventListener('keydown', close)
},[])

  const handleSendMessage = (data) => {
    api.sendMessage(data.name, data.tel, data.problem)
    .then(() => {
      closePopup();
    })
    .catch((e) => {
      return e;
    })
  } 


  return (
    <div className="page">
    <Header />
    <Main onGetHelpClick={handleGetHelpClick} onSendMessage={handleSendMessage} />
    <Footer />
    <Popup onClose={closePopup} isOpen={isPopupOpen} onSendMessage={handleSendMessage} />
    </div>
  )
}

export default App;
