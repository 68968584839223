import Tel from '../images/tired_tel.svg';
import Court from '../images/tired_court.svg'
import House from '../images/tired_house.svg';
import Cash from '../images/tired_cash.svg';
import Card from '../images/tired_card.svg';
import X from '../images/tired_x.svg';

function Tired() {
  return(
    <section className="tired">
        <h2 className="tired__title">Если Вы хотите избавиться от...</h2>
        <div className="tired__container">
          <div className="tired__cards">
            <div className="tired__card">
              <img
                className="tired__card__image"
                src={Tel}
                alt="Телефон"
              />
              <p className="tired__card__description">
                Постоянных звонков банка и коллекторов
              </p>
            </div>
            <div className="tired__card">
              <img
                className="tired__card__image"
                src={Court}
                alt="Суд"
              />
              <p className="tired__card__description">
                Неправомерных отказов в удовлетворении Ваших требований в суде
              </p>
            </div>
            <div className="tired__card">
              <img
                className="tired__card__image"
                src={House}
                alt="Дом"
              />
              <p className="tired__card__description">
                Ареста карт и имущества, запрета на выезд заграницу
              </p>
            </div>
            <div className="tired__card">
              <img
                className="tired__card__image"
                src={Cash}
                alt="Деньги"
              />
              <p className="tired__card__description">
                Неправомерных отказов в назначении пенсий и пособий
              </p>
            </div>
            <div className="tired__card">
              <img
                className="tired__card__image"
                src={Card}
                alt="Карта"
              />
              <p className="tired__card__description">
                Задолженностей, кредитов и микрозаймов
              </p>
            </div>
            <div className="tired__card">
              <img
                className="tired__card__image"
                src={X}
                alt="Нарушение"
              />
              <p className="tired__card__description">
                Нарушения Ваших законных прав и интересов
              </p>
            </div>
          </div>
        </div>
      </section>
  )
}

export default Tired;