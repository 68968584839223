import Logo from '../images/logo__help.svg';
import Corner from '../images/help_item.png';
import Bankruptcy from '../images/bankruptcy.png';
import Family from '../images/family.png';
import Inheritance from '../images/inheritance.png';
import Contract from '../images/contract.png';
import Social from '../images/social.png';
import Auto from '../images/auto.png';


function Help({onGetHelpClick}) {
  return (
  <section className="help" id="help">
        <img className="help__logo" src={Logo} alt='logo' />
        <h2 className="help__title">Мы поможем Вам с</h2>
        <div className="help__cards">
          <div className="help__card">
            <img
              className="help__card__corner"
              src={Corner}
              alt="Угол"
            />
            <img
              className="help__card__img"
              src={Bankruptcy}
              alt="Банкротство"
            />
            <h3 className="help__card__title">Банкротство физических лиц</h3>
            <p className="help__card__description">
              Законно избавиться от долгов может каждый!
            </p>
            <input className="help__card__button" type="button" onClick={onGetHelpClick} />
          </div>
          <div className="help__card">
            <img
              className="help__card__corner"
              src={Corner}
              alt="Угол"
            />
            <img
              className="help__card__img"
              src={Family}
              alt="Семейные споры"
            />
            <h3 className="help__card__title">Семейные споры</h3>
            <p className="help__card__description">
              Расторжение брака; взыскание алиментов; раздел имущества; брачный
              договор.
            </p>
            <input className="help__card__button" type="button" onClick={onGetHelpClick}/>
          </div>
          <div className="help__card">
            <img
              className="help__card__corner"
              src={Corner}
              alt="Угол"
            />
            <img
              className="help__card__img"
              src={Inheritance}
              alt="Наследственные споры"
            />
            <h3 className="help__card__title">Наследственные споры</h3>
            <p className="help__card__description">
              Поможем в оформлении наследственного имущества; вступление<br />в
              наследство.
            </p>
            <input className="help__card__button" type="button" onClick={onGetHelpClick} />
          </div>
          <div className="help__card">
            <img
              className="help__card__corner"
              src={Corner}
              alt="Угол"
            />
            <img
              className="help__card__img"
              src={Contract}
              alt="Договорное право"
            />
            <h3 className="help__card__title">Договорное право</h3>
            <p className="help__card__description">
              Подготовим договор, который всесторонне защитит Ваши интересы.
            </p>
            <input className="help__card__button" type="button" onClick={onGetHelpClick} />
          </div>
          <div className="help__card">
            <img
              className="help__card__corner"
              src={Corner}
              alt="Угол"
            />
            <img
              className="help__card__img"
              src={Social}
              alt="Право социального обеспечения"
            />
            <h3 className="help__card__title">
              Право социального обеспечения (пенсии, пособия)
            </h3>
            <p className="help__card__description">
              Поможем в назначении социальных льгот, пособий и пенсий.
            </p>
            <input className="help__card__button" type="button" onClick={onGetHelpClick} />
          </div>
          <div className="help__card">
            <img
              className="help__card__corner"
              src={Corner}
              alt="Угол"
            />
            <img
              className="help__card__img"
              src={Auto}
              alt="Автоюрист"
            />
            <h3 className="help__card__title">Автоюрист</h3>
            <p className="help__card__description">
              Консультация в вопросах ДТП, взыскание страховых выплат.
            </p>
            <input className="help__card__button" type="button" onClick={onGetHelpClick} />
          </div>
        </div>
      </section>
  )
}

export default Help;