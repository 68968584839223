function Lead({onGetHelpClick}) {
  return(
    <section className="lead">
    <div className="lead__container">
      <h1 className="lead__title">Правовая коллегия Юстиции</h1>
      <p className="lead__slogan">Ваши проблемы &mdash; наше решение!</p>
      <button className="lead__button" type="button" onClick={onGetHelpClick}>
        Получить бесплатную консультацию
      </button>
    </div>
  </section>
  )
}

export default Lead;